.mat-select-panel-wrap {
  .mat-select-panel {
    padding: 8px;
    .--mat-option-default {
      &.mat-active {
        color: #d10063;
        background-color: #f5dfdf;
        border-radius: 4px;
        &.mat-selected {
          background-color: #f5dfdf !important;
          color: #d10063 !important;
        }
      }
    }
  }
}
