.--skeleton:not(.--skeleton-children-disabled) {
  position: relative;
  background: linear-gradient(
      to right,
      rgba(230, 40, 50, 0) 0%,
      rgba(230, 40, 50, 0.04) 40%,
      rgba(230, 40, 50, 0.065) 50%,
      rgba(230, 40, 50, 0.065) 60%,
      rgba(230, 40, 50, 0.04) 70%,
      rgba(230, 40, 50, 0) 100%
    )
    0 0 / 200% 100% rgb(243, 242, 241);
  animation: shimmer 1.25s infinite linear;
  background-color: transparent;
  color: transparent !important;

  &:not(.--skeleton-children-disabled) > *:not(.--skeleton) {
    visibility: hidden !important;
  }
}

@keyframes shimmer {
  100% {
    background-position: -200% 0;
  }
}
