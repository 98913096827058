.--modal-experience-filter {
  --overflow: visible;

  .modal-wrapper {
    width: 280px;
    border-radius: 10px;
    max-height: 90%;

    experience-filter {
      height: 100%;
      contain: none !important;
      overflow: visible !important;
    }
  }
}
