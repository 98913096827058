@use "@angular/material" as mat;

@include mat.core();

$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent,
      warn: $app-warn,
    ),
  )
);

@include mat.all-component-themes($app-theme);

:root {
  --page-background-color: #f4f4f4;
  --core-header-height: 70px;
  --hover-filter: contrast(0.6) saturate(2.5) brightness(1.3);
  --box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  --ion-color-primary-gradient: linear-gradient(
    90deg,
    #ff0028 0%,
    #d80051 63%,
    #c60063 100%
  );

  // IONIC VARIABLES
  --ion-color-primary: #d10063;
  --ion-color-primary-rgb: 209, 0, 99;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #b80057;
  --ion-color-primary-tint: #d61a73;

  --ion-color-secondary: #9151f1;
  --ion-color-secondary-rgb: 145, 81, 241;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #8047d4;
  --ion-color-secondary-tint: #9c62f2;

  --ion-color-tertiary: #1e78f4;
  --ion-color-tertiary-rgb: 30, 120, 244;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #1a6ad7;
  --ion-color-tertiary-tint: #3586f5;

  --ion-color-success: #16a34a;
  --ion-color-success-rgb: 22, 163, 74;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #138f41;
  --ion-color-success-tint: #2dac5c;

  --ion-color-warning: #e66508;
  --ion-color-warning-rgb: 230, 101, 8;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ca5907;
  --ion-color-warning-tint: #e97421;

  --ion-color-danger: #ef4444;
  --ion-color-danger-rgb: 239, 68, 68;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d23c3c;
  --ion-color-danger-tint: #f15757;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #676767;
  --ion-color-medium-rgb: 103, 103, 103;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5b5b5b;
  --ion-color-medium-tint: #767676;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
