ngx-material-timepicker-content {
  --body-background-color: white;
  --primary-font-family: Poppins, sans-serif;
  --button-color: var(--ion-color-primary) !important;
  //   --dial-active-color: #fff;
  //   --dial-inactive-color: rgba(255, 255, 255, 0.5);
  --dial-background-color: var(--ion-color-secondary) !important;
  //   --dial-editable-active-color: #c6ff00 !important;
  //   --dial-editable-background-color: #fff;
  //   --clock-face-time-active-color: #fff;
  //   --clock-face-time-inactive-color: #6c6c6c;
  //   --clock-face-inner-time-inactive-color: #929292;
  //   --clock-face-time-disabled-color: #c5c5c5;
  //   --clock-face-background-color: #f0f0f0;
  --clock-hand-color: var(--ion-color-secondary) !important;
}
