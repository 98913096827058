.--full-calendar {
  overflow: auto;
  height: fit-content;
  min-height: 100%;

  * {
    font-family: Source Sans Pro;
  }
  .fc-daygrid-event-harness {
    margin: 8px;
    display: flex;

    .fc-daygrid-event {
      cursor: pointer;
      border-radius: 8px;
      padding: 8px;
      background-color: var(--ion-color-primary);
      // background-color: transparent !important;
      &:hover {
        background-color: var(--ion-color-secondary) !important;
      }
    }

    .fc-daygrid-event-dot,
    .fc-event-time {
      display: none;
    }

    .fc-event-title {
      text-wrap: wrap;
      color: white;
      border: none;
      background-color: transparent !important;
    }
  }

  .fc-header-toolbar {
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    @media screen and (min-width: 992px) {
      flex-direction: row !important;
    }

    .fc-toolbar-chunk {
      .fc-button-group {
        .fc-button {
          color: var(--ion-color-medium);
          font-weight: bold;
          background-color: transparent;
          border: 1px solid var(--ion-color-primary);
          &:hover {
            color: white;
            background-color: var(--ion-color-primary);
          }
          &.fc-button-active {
            color: white;
            background-color: var(--ion-color-primary);
          }
        }
      }
      .fc-today-button {
        background-color: var(--ion-color-primary);
      }
      .fc-toolbar-title {
        text-transform: uppercase;
        text-align: center;
        font-weight: bold;
        font-size: 24px;
      }
    }
  }
  .fc-view-harness {
    min-height: 500px;
    width: 100% !important;
    overflow: auto;

    .fc-list {
      .fc-scroller {
        .fc-list-table {
          .fc-list-day {
            .fc-list-day-cushion {
              .fc-list-day-text,
              .fc-list-day-side-text {
                color: black !important;
              }
            }
          }
        }
      }
    }

    .fc-daygrid {
      width: 100% !important;
      .fc-scrollgrid {
        width: 100% !important;
        tbody {
          width: 100% !important;
          .fc-scrollgrid-section {
            width: 100% !important;
            th,
            td {
              width: 100% !important;
              .fc-scroller-harness {
                width: 100% !important;
                .fc-scroller {
                  width: 100% !important;
                  .fc-col-header {
                    width: 100% !important;
                    .fc-col-header-cell-cushion {
                      color: var(--ion-color-medium) !important;
                    }
                  }
                  .fc-daygrid-body {
                    width: 100% !important;
                    .fc-scrollgrid-sync-table {
                      width: 100% !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // ONLY LIST
  .fc-list-event {
    cursor: pointer;
    border-radius: 8px;
    padding: 8px;
    margin: 8px;
    display: flex;
    background-color: var(--ion-color-primary);
    &:hover {
      background-color: var(--ion-color-secondary) !important;
    }

    .fc-list-event-time,
    .fc-list-event-graphic {
      display: none;
    }

    .fc-list-event-title {
      text-wrap: wrap;
      color: white;
      border: none;
      background-color: transparent !important;
    }
  }
}
