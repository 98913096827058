.chat-more-options-popover {
  .popover-wrapper {
    .popover-arrow {
      visibility: hidden;
    }
    .popover-content {
      --width: 80px !important;
      border-radius: 50px;
    }
  }
}
