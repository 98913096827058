.--toast {
  font-family: Poppins;
  --color: white;
  // margin-top: 35px;
  --max-width: 800px;
  --color: white;

  &::part(message) {
    display: flex;
    align-items: center;
  }

  &--error {
    @extend .--toast;
    --background: var(--ion-color-danger);
  }
  &--success {
    @extend .--toast;
    --background: var(--ion-color-success);
  }
}
