.--shared-modal-structure {
  .modal-shadow {
    display: none;
  }

  &::part(content) {
    border-radius: 20px;
    // --height: auto;
    // --width: auto;
    max-height: 80vh;

    // display: flex;
    // > .ion-page {
    //   display: flex;
    //   position: relative;
    //   contain: content;
    // }
  }
}
@media screen and (min-width: 992px) {
  .--shared-modal-structure {
    &::part(content) {
      max-width: 800px;
    }
  }
}
